import React from "react";
import PropTypes from "prop-types";
import ContactUsLayout1 from "./layouts/ContactUsLayout1";
import ContactUsLayout2 from "./layouts/ContactUsLayout2";

const ContactUsLayoutFactory = ({ type, data, containerCss, ...rest }) => {
  switch (type) {
    case 1:
      return (
        <ContactUsLayout1 {...rest} containerCss={containerCss} />
      );
    case 2:
      return <ContactUsLayout2 {...rest} containerCss={containerCss} />;
    default:
      return <p>Contact Us not available.</p>;
  }
};

ContactUsLayoutFactory.propTypes = {
  containerCss: PropTypes.string,
  type: PropTypes.number,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

ContactUsLayoutFactory.defaultProps = { type: 1 };

export default ContactUsLayoutFactory;
