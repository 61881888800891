import React from "react";
import PropTypes from "prop-types";
import ContentPane from "components/ui/contentPane/ContentPane";

const AboutUsView = () => {
  return (
    <ContentPane>
      <h2 className="rca-heading2">About Us</h2>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Duis at consectetur
        lorem donec massa sapien faucibus et molestie. Sit amet nisl suscipit
        adipiscing bibendum est ultricies integer. Tellus at urna condimentum
        mattis pellentesque id. Et molestie ac feugiat sed lectus vestibulum
        mattis ullamcorper velit. Nulla malesuada pellentesque elit eget
        gravida. Lacus vestibulum sed arcu non odio euismod lacinia at quis.
        Purus semper eget duis at tellus at. Eu volutpat odio facilisis mauris
        sit amet massa vitae tortor. Purus in massa tempor nec feugiat nisl
        pretium. Eu nisl nunc mi ipsum faucibus vitae. In dictum non consectetur
        a erat nam. Velit sed ullamcorper morbi tincidunt ornare massa eget. Sed
        vulputate odio ut enim blandit volutpat maecenas volutpat blandit. Enim
        lobortis scelerisque fermentum dui faucibus. Tortor aliquam nulla
        facilisi cras fermentum odio eu. Nulla posuere sollicitudin aliquam
        ultrices sagittis. Urna et pharetra pharetra massa. Convallis aenean et
        tortor at. Est ultricies integer quis auctor elit sed vulputate. Arcu
        dui vivamus arcu felis bibendum ut tristique. Arcu non odio euismod
        lacinia at quis. Eleifend mi in nulla posuere sollicitudin. Luctus
        venenatis lectus magna fringilla urna porttitor rhoncus dolor. Aliquet
        sagittis id consectetur purus ut faucibus. Amet luctus venenatis lectus
        magna fringilla urna porttitor. Lorem ipsum dolor sit amet consectetur
        adipiscing elit. Adipiscing commodo elit at imperdiet. Condimentum vitae
        sapien pellentesque habitant morbi tristique senectus et netus. Nec
        sagittis aliquam malesuada bibendum arcu vitae elementum. Curabitur
        gravida arcu ac tortor dignissim convallis. Vitae suscipit tellus mauris
        a diam maecenas sed enim ut. Nulla aliquet enim tortor at auctor. Elit
        scelerisque mauris pellentesque pulvinar pellentesque habitant morbi.
        Morbi tincidunt augue interdum velit euismod in pellentesque massa. Mi
        quis hendrerit dolor magna eget est. Tincidunt eget nullam non nisi est
        sit amet facilisis magna. Donec ac odio tempor orci dapibus ultrices.
        Vel facilisis volutpat est velit. Tristique nulla aliquet enim tortor at
        auctor urna nunc. Lacus sed turpis tincidunt id aliquet risus feugiat
        in. Sit amet justo donec enim diam. Donec et odio pellentesque diam
        volutpat commodo sed egestas. Mus mauris vitae ultricies leo. Sit amet
        cursus sit amet. Ac ut consequat semper viverra nam libero justo
        laoreet. Nascetur ridiculus mus mauris vitae ultricies leo integer
        malesuada nunc. Mi sit amet mauris commodo. Sit amet volutpat consequat
        mauris nunc congue nisi vitae suscipit. Porttitor rhoncus dolor purus
        non enim praesent. Tincidunt ornare massa eget egestas. Porttitor
        rhoncus dolor purus non enim. Porta non pulvinar neque laoreet. Egestas
        sed sed risus pretium quam vulputate dignissim suspendisse in.
        Adipiscing elit ut aliquam purus sit amet luctus. Habitant morbi
        tristique senectus et netus. Urna nunc id cursus metus aliquam eleifend
        mi in nulla. Justo nec ultrices dui sapien eget mi proin sed libero.
        Eget arcu dictum varius duis at consectetur. Congue nisi vitae suscipit
        tellus mauris a diam. Vitae proin sagittis nisl rhoncus mattis rhoncus
        urna. Quam viverra orci sagittis eu. Auctor neque vitae tempus quam
        pellentesque nec. Sollicitudin tempor id eu nisl. Vestibulum lectus
        mauris ultrices eros in cursus. Posuere morbi leo urna molestie at
        elementum eu. Feugiat nibh sed pulvinar proin gravida. Nunc congue nisi
        vitae suscipit. Elementum integer enim neque volutpat ac tincidunt
        vitae. Integer eget aliquet nibh praesent tristique magna sit amet.
        Montes nascetur ridiculus mus mauris vitae ultricies leo. In vitae
        turpis massa sed elementum tempus. Habitasse platea dictumst quisque
        sagittis purus sit amet volutpat consequat.
      </p>
    </ContentPane>
  );
};

AboutUsView.propTypes = {};

AboutUsView.defaultProps = {};

export default AboutUsView;
