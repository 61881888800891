import React from "react";
import PropTypes from "prop-types";
import { Input, Label } from "reactstrap";

const InputField = (props) => {
  const {
    name,
    label,
    type,
    value,
    showLabel,
    inputTip,
    placeholder,
    textAreaSize,
    showRequiredLabel,
    labelBold,
    onChange,
    onBlur,
  } = props;

  const handleChange = ({ target }) =>
    onChange(type === "checkbox" ? target.checked : target.value);

  const getInputComponent = () => {
    switch (type) {
      case "checkbox":
        return (
          <div>
            <Input
              id={name}
              style={{
                height: "15px",
                width: "15px",
                position: "inherit",
                marginLeft: 0,
              }}
              type={type}
              checked={value}
              placeholder={placeholder}
              onChange={handleChange}
            />
            <span className="d-inline-block ml-2 mt-1">
              {showLabel && (
                <Label className={labelBold ? "fw-600" : ""} htmlFor={name}>
                  {label}
                </Label>
              )}
              {showRequiredLabel && <span className="text-danger ml-1">*</span>}
            </span>
          </div>
        );

      case "textarea":
        return (
          <div>
            {showLabel && (
              <Label className={labelBold ? "fw-600" : ""} htmlFor={name}>
                {label}
              </Label>
            )}
            {showRequiredLabel && <span className="text-danger ml-1">*</span>}
            {inputTip && <p className="tsmall1">{inputTip}</p>}
            <div>
              <Input
                id={name}
                style={{ height: textAreaSize }}
                type={type}
                value={value}
                placeholder={placeholder}
                onChange={handleChange}
                onBlur={onBlur}
              />
            </div>
          </div>
        );

      default:
        return (
          <div>
            {showLabel && (
              <Label className={labelBold ? "fw-600" : ""} htmlFor={name}>
                {label}
              </Label>
            )}
            {showRequiredLabel && <span className="text-danger ml-1">*</span>}
            {inputTip && <p className="tsmall1">{inputTip}</p>}
            <div>
              <Input
                id={name}
                type={type}
                value={value}
                placeholder={placeholder}
                onChange={handleChange}
                onBlur={onBlur}
              />
            </div>
          </div>
        );
    }
  };

  return <>{getInputComponent()}</>;
};

InputField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.string,
  showLabel: PropTypes.bool,
  inputTip: PropTypes.string,
  placeholder: PropTypes.string,
  textAreaSize: PropTypes.string,
  showRequiredLabel: PropTypes.bool,
  labelBold: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

InputField.defaultProps = {
  showRequiredLabel: false,
  showLabel: true,
  textAreaSize: "150px",
  labelBold: false,
  onChange: () => {},
  onBlur: () => {},
};

export default InputField;
