import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";

const LoginNavButton = () => {
  return <></>;
  // return (
  //   <section>
  //     <Button className="btn btn-primary">{"Login"}</Button>
  //   </section>
  // );
};

LoginNavButton.propTypes = {};

LoginNavButton.defaultProps = {};

export default LoginNavButton;
