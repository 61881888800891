import React from "react";
import { PropTypes } from "prop-types";
import "./content-pane.scss";

const ContentPane = ({
  children,
  containerCss,
  containerStyle,
  contentCss,
  contentStyle,
  mainContentCss,
  mainContentStyle,
  contentPaddingHorizontal,
  contentPaddingVertical,
  isContentNoPadding,
  isFullWidth,
}) => {
  let innerContentCss = isFullWidth
    ? "rca-content-wrapper full-width"
    : "rca-content-wrapper";

  let contentPaddingCss = isContentNoPadding
    ? "no-padding"
    : `py-${contentPaddingVertical} px-${contentPaddingHorizontal}`;

  return (
    <section
      className={`rca-page bg-greenx ${containerCss}`}
      style={containerStyle}
    >
      <div
        className={`container-fluid ${innerContentCss} ${contentPaddingCss} ${contentCss}`}
        style={contentStyle}
      >
        <div className="row">
          <div className={`col ${mainContentCss}`} style={mainContentStyle}>
            {children}
          </div>
        </div>
      </div>
    </section>
  );
};

ContentPane.propTypes = {
  containerCss: PropTypes.string,
  containerStyle: PropTypes.object,
  contentCss: PropTypes.string,
  contentStyle: PropTypes.object,
  mainContentCss: PropTypes.string,
  mainContentStyle: PropTypes.object,
  contentPaddingHorizontal: PropTypes.string,
  contentPaddingVertical: PropTypes.string,
  isContentNoPadding: PropTypes.bool,
  isFullWidth: PropTypes.bool,
};

ContentPane.defaultProps = {
  //containerCss: "py-4",
  contentCss: "shadow-lg",
  contentPaddingHorizontal: "5",
  contentPaddingVertical: "5",
  isContentNoPadding: false,
  isFullWidth: false,
};

export default ContentPane;
