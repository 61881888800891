import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import RcButton from "components/ui/rcButton/RcButton";
import { noop } from "lodash";
import { FormInput } from "components/ui/formikFields";

const ContactForm = ({ containerCss, formSchema, onFormSubmit }) => {
  let loading = true;

  return (
    <section className={containerCss}>
      <Formik
        enableReinitialize={true}
        initialValues={{
          fname: "",
          lname: "",
          email: "",
          message: "",
        }}
        validationSchema={formSchema}
        onSubmit={onFormSubmit}
      >
        {({ handleSubmit, isSubmitting, ...rest }) => (
          <>
            <div className="row">
              <div className="col-md-6 col-12">
                <FormInput
                  type={"text"}
                  name="fname"
                  showLabel={true}
                  label={"First name"}
                  labelBold={true}
                  showRequiredLabel={true}
                />
              </div>
              <div className="col-md-6 col-12">
                <FormInput
                  type={"text"}
                  name="lname"
                  showLabel={true}
                  label={"Last name"}
                  labelBold={true}
                  showRequiredLabel={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <FormInput
                  type={"text"}
                  name="email"
                  showLabel={true}
                  label={"Email"}
                  labelBold={true}
                  showRequiredLabel={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <FormInput
                  type={"textarea"}
                  name="message"
                  showLabel={true}
                  label={"Message"}
                  labelBold={true}
                  showRequiredLabel={true}
                />
              </div>
            </div>
            <RcButton name="submit" targetName="submit" type="primary" loading={loading} onClick={handleSubmit}>
              Submit
            </RcButton>
            <RcButton name="review" targetName="submit" type="tertiary" loading={loading} onClick={handleSubmit}>
              Reset
            </RcButton>
          </>
        )}
      </Formik>
    </section>
  );
};

ContactForm.propTypes = {
  containerCss: PropTypes.string,
  formSchema: PropTypes.object,
  onFormSubmit: PropTypes.func,
};

ContactForm.defaultProps = {
  onFormSubmit: noop,
};

export default ContactForm;
