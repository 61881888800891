import React from "react";
import { FormGroup } from "reactstrap";
import PropTypes from "prop-types";
import { noop } from "lodash";
import Switch from "react-switch";

function SwitchControl({ label, value, onChange }) {
  return (
    <FormGroup>
      <label htmlFor="material-switch">
        <span>{label}</span>
        <Switch
          onChange={onChange}
          checked={value}
          onColor="#86d3ff"
          onHandleColor="#2693e6"
          handleDiameter={30}
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          height={20}
          width={48}
          className="react-switch"
          id="material-switch"
        />
      </label>
    </FormGroup>
  );
}

SwitchControl.propTypes = {
  label: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
};

SwitchControl.defaultProps = {
  SwitchControl: noop,
};

export default SwitchControl;
