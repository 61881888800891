import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import { noop } from "lodash";
import { css } from "@emotion/core";
import { PulseLoader } from "react-spinners";

const override = css`
  display: block;
  //margin: 0 auto;
  margin: 2;
  border-color: red;
  margin-top: 5px;
`;

const RcButton = ({
  name,
  children,
  buttonCss,
  containerCss,
  type,
  buttonType,
  targetName,
  loading,
  disabled,
  size,
  block,
  onClick,
  ...rest
}) => {
  let loadSpinner = (targetName === name || targetName === "") && loading;
  let disableBtn = disabled;
  let inProgressDisable = false;

  if (!loadSpinner && targetName !== "" && loading) {
    disableBtn = true;
    loadSpinner = false;
  } else if (loadSpinner) {
    inProgressDisable = true;
  }

  // 2 types of state disabled ("disabled by component prop" or "disabled by active in-Progress activity")
  let btnCss = !disableBtn //|| !disabled
    ? buttonCss
      ? buttonCss
      : `rca-btn-${type} ${inProgressDisable ? "in-progress-disabled" : ""}`
    : `rca-btn-${type} btn-disabled`; // rca-btn-disabled`;

  console.log(`rca-btn-${type} ${name} ${btnCss}`);

  return (
      <Button
        {...rest}
        type={buttonType}
        className={`mr-2 mb-2 ${btnCss}`}
        size={size}
        block={block}
        onClick={disableBtn ? noop : onClick}
        disabled={loading || disabled || disableBtn}
      >
        {loadSpinner ? (
          <PulseLoader
            css={override}
            sizeUnit={"px"}
            size={12}
            color={"#fff"}
            loading={true}
          />
        ) : (
          <span>{children}</span>
        )}
      </Button>
  );
};

RcButton.propTypes = {
  name: PropTypes.string,
  buttonCss: PropTypes.string,
  type: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  targetName: PropTypes.string,
  buttonType: PropTypes.string,
  size: PropTypes.string,
  block: PropTypes.bool,
  onClick: PropTypes.func,
};

RcButton.defaultProps = {
  type: "success",
  name: "rcButton",
  targetName: "",
  disabled: false,
  loading: false,
  size: "md",
  block: false,
  containerCss: "d-inline-block mr-2 mb-2",
  onClick: noop,
};

export default RcButton;
