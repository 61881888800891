export const arrowFuncExpoTemplate = `import React from "react";
import { View, StyleSheet } from "react-native";
import { Text } from "react-native-elements";
import PropTypes from "prop-types";

  const FuncName = () => {
      return (
          <View></View>
      );
   };

   FuncName.propTypes = {

   };

   FuncName.defaultProps = {

   };

   const styles = StyleSheet.create({
    container: {
      flex: 1,
    },
  });
   
export default FuncName;`;

export const arrowFuncReactTemplate = `import React from "react";
import PropTypes from "prop-types";

  const FuncName = ({containerCss}) => {
      return (
          <section className={containerCss}></section>
      );
   };

   FuncName.propTypes = {
    containerCss: PropTypes.string
   };

   FuncName.defaultProps = {

   };
   
export default FuncName;`;
