import FuncGenerator from "components/features/funcGenerator/FuncGenerator";
import React from "react";
import ContentPane from "components/ui/contentPane/ContentPane";

const FuncGeneratorView = () => {
  return (
    <ContentPane>
      <FuncGenerator />
    </ContentPane>
  );
};

export default FuncGeneratorView;
