import React from "react";
import PropTypes from "prop-types";
import GoogleMapReact from "google-map-react";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const GoogleMap = ({
  containerCss,
  zoomLevel,
  key,
  markerLat,
  markerLong,
  markerText,
  centerLat,
  centerLong,
}) => {
  return (
    <div className={containerCss} style={{ height: "100vh", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key }}
        defaultCenter={{
          lat: centerLat,
          lng: centerLong,
        }}
        defaultZoom={zoomLevel}
      >
        <AnyReactComponent lat={markerLat} lng={markerLong} text={markerText} />
      </GoogleMapReact>
    </div>
  );
};

GoogleMap.propTypes = {
  containerCss: PropTypes.string,
  zoomLevel: PropTypes.number,
  key: PropTypes.string,
  markerLat: PropTypes.number,
  markerLong: PropTypes.number,
  markerText: PropTypes.string,
  centerLat: PropTypes.number,
  centerLong: PropTypes.number,
};

GoogleMap.defaultProps = {
  zoomLevel: 11,
  markerText: "My Marker",
};

export default GoogleMap;
