import React from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import "./App.scss";
import "assets/styles/styles.scss";
import indexRoutes from "./routes/index";
import SplashLoader from "components/features/splashLoader/SplashLoader";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import NavigateScrollToTop from "components/ui/navigateScrollToTop/NavigateScrollToTop";

// Font sobrang awesome :)
library.add(fab, far, fas);

function App() {
  return (
    <div className="App">
      <SplashLoader>
        <Router>
          <NavigateScrollToTop />
          <Switch>
            {indexRoutes.map((prop, key) => {
              if (prop.privateRoute) {
                return (
                  <></>
                  // <PrivateRoute
                  //   path={prop.path}
                  //   key={key}
                  //   component={prop.component}
                  // />
                );
              } else {
                return (
                  <Route
                    path={prop.path}
                    key={key}
                    component={prop.component}
                  />
                );
              }
            })}
          </Switch>
        </Router>
      </SplashLoader>
    </div>
  );
}

export default App;
