import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
} from "reactstrap";
import PropTypes from "prop-types";
import { NavLink as RRNavLink } from "react-router-dom";

const NavigationBar = ({
  menus,
  companyLink,
  fixed,
  navBarRightPane,
  containerCss,
  contentStyle,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const renderMenu = (menu, i) => {
    if (!menu.type) {
      return (
        <NavItem key={i}>
          <NavLink
            tag={RRNavLink}
            to={menu.url}
            activeClassName="navbar-active"
            exact
          >
            {menu.label}
          </NavLink>
        </NavItem>
      );
    }

    return (
      <UncontrolledDropdown key={i} nav inNavbar>
        <DropdownToggle nav caret>
          {menu.label}
        </DropdownToggle>
        <DropdownMenu right>
          {menu.subMenus.map((o, indx) => (
            <div key={indx}>
              {o.type === "divider" ? (
                <DropdownItem divider />
              ) : (
                <DropdownItem tag="a" href={o.url}>
                  {o.label}
                </DropdownItem>
              )}
            </div>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  return (
    <div className="rca-nav-bar">
      <Navbar
        color="light"
        light
        expand="md"
        className="justify-content-between"
        fixed={fixed}
      >
        <div
          className={`container-fluid navbar-container ${containerCss}`}
          style={contentStyle}
        >
          <NavbarBrand href={companyLink} target="_blank">
            <img
              src="/images/logo/logo.png"
              alt="Company logo"
              className="navbar-brand-logo"
            />
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              {menus.map((o, i) => renderMenu(o, i))}
            </Nav>
            {navBarRightPane && <NavbarText>{navBarRightPane}</NavbarText>}
          </Collapse>
        </div>
      </Navbar>
    </div>
  );
};

NavigationBar.propTypes = {
  menus: PropTypes.array,
  companyLink: PropTypes.string,
  navBarRightPane: PropTypes.object,
  fixed: PropTypes.string,
  containerCss: PropTypes.string,
  contentStyle: PropTypes.object,
};

NavigationBar.defaultProps = {
  menus: [],
  companyLink: "https://rcnetlabs.com",
  fixed: "top",
};

export default NavigationBar;
