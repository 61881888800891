import React from "react";
import PropTypes from "prop-types";
import { Card, CardTitle } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CardItem = ({ title, value, icon }) => {
  return (
    <Card className="shadow-sm mb-2" body>
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div>
          <CardTitle className="tsmall1 text-muted">{title}</CardTitle>
          <p className="font-weight-bold pr-3">{value}</p>
        </div>
        <div>
          <FontAwesomeIcon icon={icon} size={"2x"} className="text-muted" />
        </div>
      </div>
    </Card>
  );
};

CardItem.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  icon: PropTypes.array,
};

CardItem.defaultProps = { icon: [] };

export default CardItem;
