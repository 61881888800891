import React from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import ModuleRoutes from "./../routes/routing";
import * as footerData from "data/footer.json";
import * as data from "data/navbar-menus.json";
import Footer from "components/features/footer/Footer";
import LoginNavButton from "components/features/loginNavButton/LoginNavButton";
import NavigationBar from "components/features/navigationBar/NavigationBar";
import Config from "config";
import RcScrollToTop from "components/ui/rcScrollToTop/RcScrollToTop";

const FullLayout = ({ location }) => {
  const getActiveView = () => {
    // Put child layout view here (custom)

    return componentRouteLoader();
  };

  const componentRouteLoader = () => {
    return (
      <Switch location={location}>
        {ModuleRoutes.map((rootLevel, key) => {
          if (rootLevel.hasItems) {
            return rootLevel.items.map((secondLevel, key2) => {
              if (secondLevel.collapse) {
                return secondLevel.items.map((thirdLevel, key3) => {
                  return thirdLevel.protectedRoute ? (
                    //   <ProtectedRoute
                    //     exact
                    //     path={thirdLevel.path}
                    //     key={key3}
                    //     claimsAuthorisationHook={thirdLevel.claimsAuthorisationHook}
                    //     component={thirdLevel.component}
                    //     {...thirdLevel}
                    //   />
                    <Route
                      exact
                      path={thirdLevel.path}
                      component={thirdLevel.component}
                      key={key3}
                    />
                  ) : (
                    <Route
                      exact
                      path={thirdLevel.path}
                      component={thirdLevel.component}
                      key={key3}
                    />
                  );
                });
              }
              return secondLevel.privateRoute ? (
                //   <ProtectedRoute
                //     exact
                //     path={secondLevel.path}
                //     key={key2}
                //     claimsAuthorisationHook={secondLevel.claimsAuthorisationHook}
                //     component={secondLevel.component}
                //     {...secondLevel}
                //   />
                <Route
                  exact
                  path={secondLevel.path}
                  component={secondLevel.component}
                  key={key2}
                />
              ) : (
                <Route
                  exact
                  path={secondLevel.path}
                  component={secondLevel.component}
                  key={key2}
                />
              );
            });
          } else if (rootLevel.redirect) {
            return (
              <Redirect from={rootLevel.path} to={rootLevel.pathTo} key={key} />
            );
          } else {
            return rootLevel.privateRoute ? (
              // <ProtectedRoute
              //   exact
              //   path={rootLevel.path}
              //   key={key}
              //   claimsAuthorisationHook={rootLevel.claimsAuthorisationHook}
              //   component={rootLevel.component}
              //   {...rootLevel}
              // />
              <Route
                exact={rootLevel.exact == null ? true : rootLevel.exact}
                path={rootLevel.path}
                component={rootLevel.component}
                key={key}
              />
            ) : (
              <Route
                exact={rootLevel.exact == null ? true : rootLevel.exact}
                path={rootLevel.path}
                component={rootLevel.component}
                key={key}
              />
            );
          }
        })}
      </Switch>
    );
  };

  return (
    <>
      <NavigationBar menus={data.menus} navBarRightPane={<LoginNavButton />} />
      <section className="content-wrapper">
        {Config.content.allowFx ? (
          <TransitionGroup>
            <CSSTransition
              key={location.key}
              timeout={{ enter: 250, exit: 250 }}
              classNames={"fade"}
            >
              <>
                <section className="route-section">
                  <main role="main">{getActiveView()}</main>
                </section>
              </>
            </CSSTransition>
          </TransitionGroup>
        ) : (
          <section className="route-section">
            <main role="main">{getActiveView()}</main>
          </section>
        )}
      </section>
      <Footer
        type={Config.footer.fatFooterType}
        copyrightText={Config.footer.copyrightText}
        data={footerData.default}
        showFatFooter={Config.footer.showFatFooter}
      />
      <RcScrollToTop />
    </>
  );
};

export default withRouter(FullLayout);
