import React from "react";
import PropTypes from "prop-types";
import ContactUsLayoutFactory from "./ContactUsLayoutFactory";

const ContactUs = ({ containerCss, ...rest }) => {
  return (
    <section className={containerCss}>
      <ContactUsLayoutFactory {...rest} />
    </section>
  );
};

ContactUs.propTypes = {
  containerCss: PropTypes.string,
  layoutContainerCss: PropTypes.string,
  type: PropTypes.number,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  formSchema: PropTypes.object,
};

ContactUs.defaultProps = {};

export default ContactUs;
