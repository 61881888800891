import AboutUsView from "views/about/aboutView";
import ContactUsView from "views/contactUs/contactUsView";
import DemoView from "views/demo/demoView";
import FuncGeneratorView from "views/funcGenerator/funcGeneratorView";
import HomeView from "views/home/homeView";

/**
 * Route properties see usage.txt
 */
const ModuleRoutes = [
  // Modules

  // Core Routes
  {
    path: "/",
    label: "Home",
    component: HomeView,
    privateRoute: false,
  },
  {
    path: "/func-codegen",
    label: "About Us",
    component: FuncGeneratorView,
    privateRoute: false,
  },
  {
    path: "/about-us",
    label: "About Us",
    component: AboutUsView,
    privateRoute: false,
  },

  // Catch all Route
  // {
  //   path: "",
  //   component: ContentPage,
  //   privateRoute: false,
  // },
];

export default ModuleRoutes;
