import React from "react";
import PropTypes from "prop-types";
import Splash from "components/ui/splash/Splash";

const SplashLoader = ({ children }) => {
  const handleDataLoader = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => resolve(), 3000);
    });
  };

  return <Splash onDataLoad={handleDataLoader}>{children}</Splash>;
};

SplashLoader.propTypes = {};

SplashLoader.defaultProps = {};

export default SplashLoader;
