import React from "react";
import { Input } from "reactstrap";
import PropTypes from "prop-types";
import "./news-letter.scss";
import RcButton from "../rcButton/RcButton";
import { noop } from "lodash";

const NewsLetter = ({
  title,
  description,
  buttonTitle,
  inputPlaceholder,
  containerCss,
  buttonType,
  onClick,
}) => {
  return (
    <section className={containerCss}>
      <h4>{title}</h4>
      <small>{description}</small>
      <div className="d-flex flex-row">
        <Input className="mr-1" placeholder={inputPlaceholder} />
        <RcButton type={buttonType} onClick={onClick}>
          {buttonTitle}
        </RcButton>
      </div>
    </section>
  );
};

NewsLetter.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  containerCss: PropTypes.string,
  buttonTitle: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  buttonType: PropTypes.string,
  onSubscribe: PropTypes.func,
};

NewsLetter.defaultProps = {
  title: "Sign up for our newsletter",
  description:
    "Sign up for our mailing list to receive new product alerts, special offers.",
  containerCss: "news-letter",
  buttonTitle: "Subscribe",
  inputPlaceholder: "Email address",
  buttonType: "info",
  onSubscribe: noop
};

export default NewsLetter;
