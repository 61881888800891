import React from "react";
import PropTypes from "prop-types";
import FooterBase from "components/ui/footer/FooterBase";
import FatFooter from "./FatFooter";

const Footer = ({ copyrightText, showFatFooter, ...rest }) => {
  let year = new Date().getFullYear();
  let finalCopyrightText = copyrightText.replace("@year@", year);

  return (
    <FooterBase fatFooter={showFatFooter ? <FatFooter {...rest} /> : <></>}>
      <p dangerouslySetInnerHTML={{ __html: finalCopyrightText }} className="mx-4" />
    </FooterBase>
  );
};

Footer.propTypes = {
  type: PropTypes.number,
  copyrightText: PropTypes.string,
  showFatFooter: PropTypes.bool,
  showSocialLinks: PropTypes.bool
};

Footer.defaultProps = { showFatFooter: false };

export default Footer;
