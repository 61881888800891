import React from "react";
import PropTypes from "prop-types";
import "./short-header1.scss";

const ShortHeader1 = ({
  heading,
  subHeading,
  imagePath,
  layout,
  containerCss,
  height,
}) => {
  let imgStyle = {
    minHeight: height,
    backgroundImage: `url(${imagePath})`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundRepeat: "repeat",
  };

  return (
    <>
      {layout === "topLeft" && (
        <div className={`short-header1-container ${containerCss}`}>
          <div className="image" style={imgStyle} />
          <h2 className="rca-heading2">{heading}</h2>
          <div className="line-separator" />
          <h5>{subHeading}</h5>
        </div>
      )}
      {layout === "center" && (
        <div className={`short-header1-container ${containerCss}`}>
          <div
            className="layout-center d-flex flex-column justify-content-center align-items-center"
            style={imgStyle}
          >
            <h2 className="rca-heading2">{heading}</h2>
            <h5 className="override-h5 mt-4">{subHeading}</h5>
          </div>
        </div>
      )}
    </>
  );
};

ShortHeader1.propTypes = {
  containerCss: PropTypes.string,
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  imagePath: PropTypes.string,
  layout: PropTypes.string,
  height: PropTypes.string,
};

ShortHeader1.defaultProps = { layout: "center", height: "420px" };

export default ShortHeader1;
