import React from "react";
import PropTypes from "prop-types";
import { CardItem } from "components/ui/card";

const ContactSideInfo = ({ data, containerCss }) => {
  return (
    <section className={containerCss}>
      {data.map((o, i) => (
        <CardItem key={i} title={o.title} value={o.value} icon={o.icon} />
      ))}
    </section>
  );
};

ContactSideInfo.propTypes = {
  data: PropTypes.array,
  containerCss: PropTypes.string,
};

ContactSideInfo.defaultProps = { data: [] };

export default ContactSideInfo;
