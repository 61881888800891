import React from "react";
import PropTypes from "prop-types";
import ContentPane from "components/ui/contentPane/ContentPane";

const ContactUsLayout2 = ({ containerCss }) => {
  return (
    <section className={containerCss}>
      <ContentPane>
        <h2>Layout 2</h2>
      </ContentPane>
    </section>
  );
};

ContactUsLayout2.propTypes = {
  containerCss: PropTypes.string,
};

ContactUsLayout2.defaultProps = {};

export default ContactUsLayout2;
