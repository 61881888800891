import React, { useState, useCallback } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Label,
  Button,
} from "reactstrap";
import { useClipboard } from "use-clipboard-copy";
import useSound from "use-sound";
import copySoundFx from "assets/sounds/copy-sound.wav";
import "./func-generator.scss";
import {
  arrowFuncReactTemplate,
  arrowFuncExpoTemplate,
} from "./templates/arrowFunc";
import SwitchControl from "../switchControl/SwitchControl";
import { funcReactTemplate, funcExpoTemplate } from "./templates/funcNormal";

const FuncGenerator = () => {
  //let templateExpo = arrowFuncExpoTemplate;
  ///let template = arrowFuncReactTemplate;

  const [isReactNative, setIsReactNative] = useState(false);
  const [isArrowFunc, setIsArrowFunc] = useState(false);
  const [targetTemplate, setTargetTemplate] = useState(funcReactTemplate);
  const [funcName, setFuncName] = useState("");
  const clipboard = useClipboard();
  const [play, { stop }] = useSound(copySoundFx);

  const handleFuncName = (e) => {
    setFuncName(e.target.value);
    processText(e.target.value, isReactNative);
  };

  const handleCopy = () => {
    clipboard.copy(targetTemplate);
    play();
  };

  const getTemplate = (isReactNativeProject, isArrowFunction) => {
    if (isArrowFunction) {
      if (isReactNativeProject) {
        return arrowFuncExpoTemplate;
      }

      return arrowFuncReactTemplate;
    } else {
      if (isReactNativeProject) {
        return funcExpoTemplate;
      }

      return funcReactTemplate;
    }
  };

  const handleProjectType = (value) => {
    setIsReactNative(value);
    let template = getTemplate(value, isArrowFunc);
    setTargetTemplate(template);
    processText(funcName, value, isArrowFunc);
  };

  const processText = (fname, reactNative, isArrowFunction) => {
    let replacedValue = getTemplate(reactNative, isArrowFunction).replace(
      /funcName/gi,
      fname
    );
    setTargetTemplate(replacedValue);
  };

  const handleArrowFunc = (value) => {
    setIsArrowFunc(value);
    let template = getTemplate(isReactNative, value);
    setTargetTemplate(template);
    processText(funcName, isReactNative, value);
  };

  return (
    <div className="content">
      <Card>
        <CardHeader>
          <h3>Functional Component</h3>
        </CardHeader>
        <CardBody>
          <SwitchControl
            label="React Native"
            value={isReactNative}
            onChange={handleProjectType}
          />
          <SwitchControl
            label="Arrow Function"
            value={isArrowFunc}
            onChange={handleArrowFunc}
          />
          <FormGroup>
            <Label for="exampleText">Function Name</Label>
            <Input
              //ref={clipboard.target}
              type="text"
              name="funcName"
              onChange={handleFuncName}
            />
          </FormGroup>
          <Button
            color="primary"
            block={true}
            className="mb-3"
            onClick={handleCopy}
          >
            Copy
          </Button>
          <FormGroup>
            <Label for="exampleText">Code Generated</Label>
            <Input
              id="exampleText"
              type="textarea"
              name="text"
              value={targetTemplate}
              rows={10}
              readOnly={true}
            />
          </FormGroup>
          {/* {clipboard.target} */}
        </CardBody>
      </Card>
    </div>
  );
};

export default FuncGenerator;
