import React from "react";
import { useHistory } from "react-router-dom";
import ContentPane from "components/ui/contentPane/ContentPane";
import "./home.scss";

const HomeView = () => {
  const history = useHistory();

  const handleFuncGen = () => {
    history.push("func-codegen");
  };

  return (
    <ContentPane containerCss="home">
      <h2>Toolkits</h2>

      <div className="d-flex flex-row justify-content-center mt-5">
        <div className="menu-item" onClick={handleFuncGen}>
          <div>React / React Native Func Generator</div>
        </div>
        <div className="menu-item">
          <div>Redux Code Gen</div>
        </div>
      </div>
    </ContentPane>
  );
};

export default HomeView;
