import React from "react";
import PropTypes from "prop-types";
import ContentPane from "components/ui/contentPane/ContentPane";
import "./contact-us-layout1.scss";
import { ShortHeader1 } from "components/ui/header/shortHeader1";
import ContactSideInfo from "../contactSideInfo/ContactSideInfo";
import { GoogleMap } from "components/ui/gmap";
import Config from "config";
import * as data from "data/contactUs/contact-us-layout1.json";
import ContactForm from "../contactForm/ContactForm";

const ContactUsLayout1 = ({ containerCss, formSchema }) => {
  const { header, content, gMap } = data.default;

  return (
    <ContentPane
      isContentNoPadding={true}
      containerCss={`contact-us-container ${containerCss}`}
    >
      <ShortHeader1
        heading={header.heading}
        subHeading={header.subHeading}
        imagePath={header.headerImage}
        layout={header.layout}
      />

      <div className="contact-info-container">
        <h2 className="py-4">{content.heading}</h2>

        {/* Contact Info and Form */}
        <div className="row">
          <div className="col-md col-xs-12 mb-5">
            <ContactSideInfo data={content.contactSideInfo} />
          </div>
          <div className="col-md col-xs-12">
            <ContactForm formSchema={formSchema} />
          </div>
        </div>
      </div>

      {/* Map */}
      <div className="map-container">
        <h2 className="py-4 map-title">{gMap.heading}</h2>
        <div className="row map">
          <div className="col mx-2 mb-2">
            <GoogleMap
              key={Config.gmap.gmapKey}
              centerLat={Config.gmap.centerLat}
              centerLong={Config.gmap.centerLng}
              markerLat={Config.gmap.markerLat}
              markerLong={Config.gmap.markerLng}
            />
          </div>
        </div>
      </div>
    </ContentPane>
  );
};

ContactUsLayout1.propTypes = {
  containerCss: PropTypes.string,
};

ContactUsLayout1.defaultProps = {};

export default ContactUsLayout1;
